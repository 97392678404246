import React from "react";
import { getStyles } from "../../../assets/js/utils/functions";
import { getSizedImage } from "../../../assets/js/utils/utils";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import useSlider from "../../hooks/useSlider";
import LazyImage from "../../lazy-image";
import { AppBtn } from "../../ui/buttons";
import AnimatedHeaderText from "../commons/animated-header-text";

const WhatYouCanSell = () => {
  const { screen } = useScreenSize();
  const itemsCount = items.length;
  const { next, slider, slide, previous, slides, currentSlide, switchSlides } = useSlider({
    slides: itemsCount,
    sameWidthAsSlider: false,
    gap: 24,
  });

  return (
    <section className="bg-primary-pastel body-padding body-y-padding">
      <div className="container">
        <AnimatedHeaderText
          className="mb-10 sm:mb-12.5 lg:mb-15"
          series={[
            ["What", "you", "can", "sell"],
            ["with", "Catlog"],
          ]}
        />
      </div>
      <div className="">
        <ul
          className="grid grid-cols-[repeat(5,70%)] sm:grid-cols-[repeat(49%)] md:grid-cols-[repeat(45%)] lg:grid-cols-[repeat(5,34%)] gap-8 xl:px-[calc((100vw-1200px)/2)] negative-body-padding overflow-hidden hide-for-observer"
          ref={slider}
        >
          {items.map((i, index) => (
            <article
              className="w-full pt-[120%] relative item-card overflow-hidden item-radius sell-item anim-word-up"
              key={index}
              ref={slide}
              style={getStyles({ "--delay": `${index * 0.2}s`, "--from": "15%", "--duration": "1s" })}
            >
              <figure className="absolute top-0 left-0 h-full w-full item-radius safari-overflow-fix">
                <LazyImage
                  src={getSizedImage(i.image, { [BreakPoint.xs]: "w_350", default: "w_450" }, screen)}
                  alt={i.title}
                  className="h-full w-full object-cover item-radius"
                />
              </figure>
              <div className="w-full h-[200%] absolute top-0 left-0 item-overlay item-radius">
                <div className="h-1/2 p-5 sm:p-5.5 md:p-6 lg:p-7.5 flex flex-col justify-end">
                  <span className="text-white font-bold text-lg sm:text-xl lg:text-2xl !leading-none">{i.title}</span>
                </div>
                <div className="h-1/2 p-5 sm:p-5.5 md:p-6 lg:p-7.5 flex flex-col justify-between items-start">
                  <span className="text-white text-1sm md:text-base xl:text-lg ">{i.description}</span>

                  <AppBtn color="white" size="lg" className="mt-7.5 md:mt-8 lg:mt-11">
                    Get started
                    {/* prettier-ignore */}
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" className="ml-2.5 transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
                    <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="currentColor" />
                  </svg>
                  </AppBtn>
                </div>
              </div>
            </article>
          ))}
        </ul>
      </div>
      <div className="container">
        <div className="mt-8 sm:mt-10 lg:mt-12.5 justify-between flex items-center w-full">
          <ul className="flex items-center space-x-2">
            {slides > 1 &&
              Array(slides)
                .fill(0)
                .map((_, index) => (
                  <li
                    className={`h-3 w-3 rounded-full ${
                      index === currentSlide ? "bg-[#060538]" : "bg-[#DDDCDB]"
                    } cursor-pointer`}
                    key={index}
                    onClick={(e) => switchSlides(index)}
                  ></li>
                ))}
          </ul>
          <div className="flex items-center space-x-3">
            <button
              className="h-11.25 sm:h-12.5 w-11.25 sm:w-12.5 rounded-full bg-white flex items-center justify-center shadow-lg text-primary-500"
              onClick={previous}
              aria-label="previous"
            >
              {/* prettier-ignore */}
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M13 7H1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7 1L1 7L7 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
            <button
              className="h-11.25 sm:h-12.5 w-11.25 sm:w-12.5 rounded-full text-white flex items-center justify-center shadow-lg bg-primary-500"
              onClick={next}
              aria-label="next"
            >
              {/* prettier-ignore */}
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" >
                <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="currentColor"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

const items = [
  {
    title: "Food & Drinks",
    description:
      "Pasta, milkshakes, finger bites, contenental dishes, wines? You name it. If you sell fast food, run a resturant or cloud kitchen? Catlog is best way for your customers to place their orders.",
    image: "https://res.cloudinary.com/catlog/image/upload/v1670390752/front-pages/items-to-sell/food.webp",
  },
  {
    title: "Fashion Items",
    description:
      "Do you sell jeans, pretty gowns, sneakers, bespoke fashion items or even fabric materials?  Catlog helps you give your customers a simple list, so they can see all the options available to them and make orders.",
    image: "https://res.cloudinary.com/catlog/image/upload/v1670390751/front-pages/items-to-sell/fashion.webp",
  },
  {
    title: "Gadgets",
    description:
      "From iphones, to earbuds, smartwatches and laptops, Use catlog to give your customers an easy way to browse through all the items you sell and place orders without having to answer too many questions.",
    image: "https://res.cloudinary.com/catlog/image/upload/v1670390752/front-pages/items-to-sell/gadgets.webp",
  },
  {
    title: "Beauty & Makeup",
    description:
      "Beauty products ranging from makeup, perfumes, hair and skin care products can be showcased beautifully to your customers with Catlog. Your customers get to easily see all the varieties of products you sell.",
    image: "https://res.cloudinary.com/catlog/image/upload/v1670390751/front-pages/items-to-sell/beauty.webp",
  },
  {
    title: "Physical Items",
    description:
      "When you use catlog, you gives your customers an easy and hassle-free experience when they want to buy goods such as kitchen appliances, household equipment, furniture, cooking and eating utensils.",
    image: "https://res.cloudinary.com/catlog/image/upload/v1670390756/front-pages/items-to-sell/furniture.webp",
  },
];

export default WhatYouCanSell;
