import { paramsFromObject } from "../assets/js/utils/functions";
import { GetPlansParams } from "./interfaces/subscriptions.interface";
import { request } from "./utils";

const GetPlans = (data: GetPlansParams) => {
  const params = paramsFromObject(data);
  return request(`plans?${params}`, "get");
};

export { GetPlans };
