import classNames from "classnames";
import React, { ReactElement, useEffect, useState } from "react";

interface Props {
  onChange: (id: string) => void;
  items: { id: string; label: string; icon?: ReactElement }[];
  value?: string;
  size?: "sm" | "md" | "lg";
}
const TabToggle: React.FC<Props> = ({ onChange, items, value, size = "lg" }) => {
  const [selected, setSelected] = useState(value ?? items[0].id);
  useEffect(() => {
    if (value) setSelected(value);
  }, [value]);
  const handleToggle = (index: number) => {
    const id = items[index].id;
    if (id !== selected) {
      setSelected(id);
      onChange(id);
    }
  };
  return (
    <div className=" bg-white p-1.25 rounded-full">
      {items.map((item, index) => {
        const isSelected = item.id === selected;
        return (
          <button
            className={classNames(
              "px-3.75 py-2.5 sm:px-6.25 sm:py-3 lg:px-8 lg:py-3.75 transition-all ease-out duration-300",
              {
                "bg-primary-100 text-primary-500 rounded-full shadow-card": isSelected,
                "text-black": !isSelected,
              }
            )}
            onClick={() => handleToggle(index)}
            key={index}
          >
            <span className="block font-medium text-1sm sm:text-base lg:text-lg">{item.label}</span>
          </button>
        );
      })}
    </div>
  );
};
export default TabToggle;
