import classNames from "classnames";
import React, { Fragment } from "react";
import { getStyles } from "../../../assets/js/utils/functions";
import { getSizedImage } from "../../../assets/js/utils/utils";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import useSlider from "../../hooks/useSlider";
import LazyImage from "../../lazy-image";
import AnimatedHeaderText from "../commons/animated-header-text";

interface IProps {
  whiteBg?: boolean;
}

const PricingTestimonials: React.FC<IProps> = ({ whiteBg = false }) => {
  const tCount = testimonials.length;
  const { screen } = useScreenSize();
  const { next, slider, slide, previous, slides, currentSlide, switchSlides } = useSlider({
    slides: tCount,
    sameWidthAsSlider: true,
    gap: 0,
  });

  return (
    <section className={`body-padding body-y-padding ${whiteBg ? "bg-white" : "bg-primary-pastel"}`}>
      <div className="container">
        <AnimatedHeaderText
          className="mb-10 sm:mb-12.5 lg:mb-15"
          series={[
            ["What", "business", "owners"],
            ["say", "about", "catlog"],
          ]}
        />
        <div className="w-full flex flex-col md:grid md:grid-cols-[52%,48%] lg:grid-cols-[54%,46%] xl:grid-cols-[52%,48%] mt-8 sm:mt-10 md:mt-12.5 lg:mt-16 hide-for-observer">
          <div className="mb-6.25 sm:mb-10 md:mb-0 md:pr-12.5 lg:pr-15 xl:pr-20 h-full">
            <figure className="w-full h-full rounded-[25px] sm:rounded-30 relative overflow-hidden pt-[85%] sm:pt-[68%] md:pt-0 safari-overflow-fix">
              {testimonials.map((t, index) => (
                <Fragment key={index}>
                  {index === currentSlide && (
                    <LazyImage
                      src={getSizedImage(t.image, { [BreakPoint.xs]: "w_650", default: "w_850" }, screen)}
                      alt=""
                      loaderClasses="w-full h-full object-cover absolute top-0 rounded-[25px] sm:rounded-30"
                      className={classNames(
                        "w-full h-full object-cover absolute transform transition-all ease-out image-appear rounded-[25px] top-0 left-0 sm:rounded-30"
                      )}
                    />
                  )}
                </Fragment>
              ))}
            </figure>
          </div>
          <div>
            <ul className="w-full items-center overflow-hidden" ref={slider}>
              {testimonials.map((t, index) => (
                <Fragment key={index}>
                  {index === currentSlide && (
                    <li className="w-full flex-1" ref={slide as any}>
                      <figure
                        className={`h-12.5 w-12.5 sm:h-15 sm:w-15 lg:h-18 lg:w-18 rounded-full overflow-hidden relative ${
                          !whiteBg ? "bg-white" : "bg-grey-fields-100"
                        }`}
                      >
                        <LazyImage
                          loaderClasses="h-full w-full rounded-full"
                          src={t.logo}
                          className="h-full w-full rounded-full image-appear"
                          alt={`${t.business} logo`}
                          style={getStyles({ "--delay": "0.25s" })}
                        />
                      </figure>
                      <div className="mt-5 sm:mt-7.5">
                        <p
                          className="mt-5 sm:mt-7.5 text-black font-semibold text-xl sm:text-2xl lg:text-[28px] xl:text-3xl !leading-[1.5] anim-fade-up"
                          style={getStyles({ "--delay": "0.5s" })}
                        >
                          “{t.story}
                        </p>
                        <div
                          className="mt-3.75 sm:mt-5 anim-word-up"
                          style={getStyles({ "--delay": "0.6s", "--from": "15%" })}
                        >
                          <h3 className="font-bold text-lg sm:text-xl lg:text-2lg text-primary-500">{t.name}</h3>
                          <span className="text-sm sm:text-1sm lg:text-base font-medium text-black">{t.business}</span>
                        </div>
                      </div>
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
            <div className="mt-6.25 sm:mt-8 lg:mt-10 justify-between flex items-center w-full">
              <ul className="flex items-center space-x-2">
                {slides > 1 &&
                  Array(slides)
                    .fill(0)
                    .map((_, index) => (
                      <li
                        className={`h-3 w-3 rounded-full ${
                          index === currentSlide ? "bg-[#060538]" : "bg-[#DDDCDB]"
                        } cursor-pointer`}
                        key={index}
                        onClick={(e) => switchSlides(index)}
                      ></li>
                    ))}
              </ul>
              <div className="flex items-center space-x-3">
                <button
                  className="h-11.25 sm:h-12.5 w-11.25 sm:w-12.5 rounded-full bg-white flex items-center justify-center shadow-lg text-primary-500"
                  onClick={previous}
                  aria-labelledby="back"
                >
                  {/* prettier-ignore */}
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M13 7H1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7 1L1 7L7 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
                <button
                  className="h-11.25 sm:h-12.5 w-11.25 sm:w-12.5 rounded-full text-white flex items-center justify-center shadow-lg bg-primary-500"
                  onClick={next}
                  aria-labelledby="next"
                >
                  {/* prettier-ignore */}
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" >
                    <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="currentColor"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const testimonials = [
  {
    image: "https://res.cloudinary.com/catlog/image/upload/v1670388227/front-pages/testimonials/bernadines.webp",
    story:
      "I love that customers just visit my Catlog link, pick what they want and I just get a message on WhatsApp with what they want to order, and their delivery details, it’s just too sweet",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305498/website-store-logos/bernadines.webp",
    name: "Chukwuma Rejoice",
    business: "Bernadines Bistro (Cloud kitchen)",
  },
  {
    image: "https://res.cloudinary.com/catlog/image/upload/v1670388227/front-pages/testimonials/pancake-boss.webp",
    story:
      "Catlog has saved me from having long and sometimes unnecessary conversations with customers in the name of placing orders. I have all the information on their orders at a glance.",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305498/website-store-logos/pancake-boss.webp",
    name: "Chidinma Onyeiwu",
    business: "Pancake Boss (Breakfast Store)",
  },
  {
    image:
      "https://res.cloudinary.com/catlog/image/upload/v1674409290/front-pages/testimonials/311610357_5835405603157214_5941277146995908478_n_auto_x2_light_ai.webp",
    story:
      "Catlog helps me save time, I no longer have to send 40 pictures to customers before they buy. With Catlog, my customers are able to easily purchase sneakers or add to cart to buy later.",
    logo: "https://res.cloudinary.com/catlog/image/upload/v1670305497/website-store-logos/jis.webp",
    name: "Surprise Ayodele",
    business: "Jisstore NG (Sneaker Store)",
  },
  {
    image: "https://res.cloudinary.com/catlog/image/upload/v1674470047/front-pages/testimonials/adeola.webp",
    story:
      "Catlog has helped cut 50% of the conversations we have with customers. Instead of going back and forth with every customer, we just send a link and they are able to select what they want.",
    logo: "https://res.cloudinary.com/catlog/image/upload/v1674470228/website-store-logos/mazzini.webp",
    name: "Adeola Owosho",
    business: "Mazzini's Box (Workspace Accessories)",
  },
  {
    image: "https://res.cloudinary.com/catlog/image/upload/v1674468326/front-pages/odeya-new.webp",
    story:
      "Catlog gets me closer to my Clients in ways that helps my business grow with ease. I'm able to monitor my best performing products and improve on the others through my page insight.",
    logo: "https://res.cloudinary.com/catlog/image/upload/v1674376695/website-store-logos/odeya-logo.webp",
    name: "Mosunmola",
    business: "Odeya Auto Gele (Headgear Store)",
  },
  // {
  //   image: "https://res.cloudinary.com/catlog/image/upload/v1674415197/front-pages/testimonials/adiya.webp",
  //   story:
  //     "Catlog is one of the best and most affordable platforms that helps me take orders with detailed customer info. It makes it really easy to attend to many clients and close sales.",
  //   logo: "https://res.cloudinary.com/catlog/image/upload/v1670305497/website-store-logos/jis.webp",
  //   name: "Surprise Ayodele",
  //   business: "Jisstore NG",
  // },
  // {
  //   image:
  //     "https://res.cloudinary.com/catlog/image/upload/v1674414299/front-pages/testimonials/WhatsApp_Image_2023-01-22_at_7.40.55_PM_auto_x2_toned_light_ai.webp",
  //   story:
  //     "I love the fact that Catlog is user friendly, I found it really easy to navigate through once I signed up and their subscription plans are also very affordable",
  //   logo: "https://res.cloudinary.com/catlog/image/upload/v1674376695/website-store-logos/odeya-logo.webp",
  //   name: "Mosunmola",
  //   business: "Kira's Empire",
  // },
];

// https://res.cloudinary.com/catlog/image/upload/v1674387424/front-pages/testimonials/kira-new.jpg

export default PricingTestimonials;
