import React from "react";
import { CountryInterface } from "../../../assets/interfaces";
import Dropdown from "../../ui/dropdown";
import NigeriaFlag from "../../../assets/icons/front-pages/nigeria.svg";
import { getStyles } from "../../../assets/js/utils/functions";

interface PricingCountrySelectorProps {
  setCountry: (country: string) => void;
  country: string;
  countries: CountryInterface[];
}

const PricingCountrySelector: React.FC<PricingCountrySelectorProps> = ({ setCountry, country, countries }) => {
  const dropdownOptions = !countries
    ? []
    : countries?.map((c) => ({
        text: `${c.emoji} ${c.name}`,
        onClick: () => setCountry(c.code),
      }));

  return (
    <Dropdown items={dropdownOptions}>
      <button className="flex items-center bg-white py-2 lg:py-2.5 px-2.5 sm:px-3 lg:px-3.75 border border-grey-border border-opacity-50 rounded-30 no-outline dropdown-toggle relative">
        <div className="pr-2.5 sm:pr-3 lg:pr-3.75 border-r border-grey-border border-opacity-50">
          <figure className="h-6.25 w-6.25 sm:h-7.5 sm:w-7.5 lg:h-9 lg:w-9 rounded-full bg-grey-fields-100 overflow-hidden">
            {countryFlags[country]}
          </figure>
        </div>
        <div className="pl-2.5 sm:pl-3 lg:pl-3.75 flex items-center text-dark">
          <span className="text-1sm sm:text-base lg:text-lg font-semibold inline-block w-14 sm:w-16">
            {countries?.find((c) => country === c.code)?.name ?? "Nigeria"}
          </span>
          {/* prettier-ignore */}
          <svg width="18" viewBox="0 0 15 15" fill="none" className="ml-1.25 mt-px text-dark">
            <path d="M11.2 5.11249H7.30621H3.79996C3.19996 5.11249 2.89996 5.83749 3.32496 6.26249L6.56247 9.49999C7.08122 10.0187 7.92497 10.0187 8.44372 9.49999L9.67497 8.26874L11.6812 6.26249C12.1 5.83749 11.8 5.11249 11.2 5.11249Z" fill="currentColor" />
          </svg>
        </div>
      </button>
    </Dropdown>
  );
};

const countryFlags = {
  NG:
    // prettier-ignore
    <svg height="100%" viewBox="0 0 56 40" fill="none" className="transform -translate-x-1/2 relative left-1/2">
      <rect x="0.5" y="0.5" width="55" height="39" rx="3.5" fill="white" stroke="white"/>
      <mask id="mask0_1690_8219" style={getStyles({"mask-type": "alpha"})} maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="40">
        <rect x="0.5" y="0.5" width="55" height="39" rx="3.5" fill="white" stroke="white"/>
      </mask>
      <g mask="url(#mask0_1690_8219)">
        <rect x="35" width="21" height="40" fill="#189B62"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0 40H21V0H0V40Z" fill="#189B62"/>
      </g>
    </svg>,
  GH:
    //prettier-ignore
    <svg height="100%" viewBox="0 0 56 40" fill="none" className="transform -translate-x-1/2 relative left-1/2">
      <rect width="56" height="40" rx="4" fill="white"/>
      <mask id="mask0_1690_8224" style={getStyles({"mask-type": "alpha"})} maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="40">
        <rect width="56" height="40" rx="4" fill="white"/>
      </mask>
      <g mask="url(#mask0_1690_8224)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 13.3333H56V0H0V13.3333Z" fill="#E71F37"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0 40.0001H56V26.6667H0V40.0001Z" fill="#118B56"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0 26.6666H56V13.3333H0V26.6666Z" fill="#FDD64C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28 23.2234L24.0815 26.0601L25.5685 21.4567L21.6597 18.6066L26.4972 18.5982L28 14L29.5028 18.5982L34.3404 18.6066L30.4316 21.4567L31.9186 26.0601L28 23.2234Z" fill="#262626"/>
      </g>
    </svg>,
};

export default PricingCountrySelector;
