import cx from "classnames";
import React, { useState } from "react";
import { CountryInterface, Plan } from "../../assets/interfaces";
import { toCurrency } from "../../assets/js/utils/functions";
import { AppBtn } from "../ui/buttons";

interface Props {
  plan: Plan;
  isLoading?: boolean;
  isRecommended?: boolean;
}

const PlanCard: React.FC<Props> = ({ plan, isRecommended }) => {
  const { name, amount, description: descriptions, interval_text, country, type } = plan;
  const description = "For small businesses to plan and track work more efficiently ";
  const [showDescription, setShowDescription] = useState(false);

  return (
    <>
      <article className="rounded-20 border-grey-border border-opacity-50 border min-w-[280px] max-w-[340px] overflow-hidden flex-grow-0 bg-white">
        <div className="p-5 sm:p-6.25 lg:p-7.5">
          <div className="flex items-center justify-between mb-2 ">
            <h5 className={cx("mt-1 text-2lg sm:text-1xl md:text-2xl text-black-secondary font-black")}>{name}</h5>
            {isRecommended && (
              <div className="flex items-center justify-between px-2.5 py-1 bg-accent-green-500 bg-opacity-10 rounded-full">
                {/* prettier-ignore */}
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.17 8.055L15.15 6.87C14.955 6.645 14.7975 6.225 14.7975 5.925V4.65C14.7975 3.855 14.145 3.2025 13.35 3.2025H12.075C11.7825 3.2025 11.355 3.045 11.13 2.85L9.94503 1.83C9.42753 1.3875 8.58003 1.3875 8.05503 1.83L6.87753 2.8575C6.65253 3.045 6.22503 3.2025 5.93253 3.2025H4.63503C3.84003 3.2025 3.18753 3.855 3.18753 4.65V5.9325C3.18753 6.225 3.03003 6.645 2.84253 6.87L1.83003 8.0625C1.39503 8.58 1.39503 9.42 1.83003 9.9375L2.84253 11.13C3.03003 11.355 3.18753 11.775 3.18753 12.0675V13.35C3.18753 14.145 3.84003 14.7975 4.63503 14.7975H5.93253C6.22503 14.7975 6.65253 14.955 6.87753 15.15L8.06253 16.17C8.58003 16.6125 9.42753 16.6125 9.95253 16.17L11.1375 15.15C11.3625 14.955 11.7825 14.7975 12.0825 14.7975H13.3575C14.1525 14.7975 14.805 14.145 14.805 13.35V12.075C14.805 11.7825 14.9625 11.355 15.1575 11.13L16.1775 9.945C16.6125 9.4275 16.6125 8.5725 16.17 8.055ZM12.12 7.5825L8.49753 11.205C8.39253 11.31 8.25003 11.37 8.10003 11.37C7.95003 11.37 7.80753 11.31 7.70253 11.205L5.88753 9.39C5.67003 9.1725 5.67003 8.8125 5.88753 8.595C6.10503 8.3775 6.46503 8.3775 6.68253 8.595L8.10003 10.0125L11.325 6.7875C11.5425 6.57 11.9025 6.57 12.12 6.7875C12.3375 7.005 12.3375 7.365 12.12 7.5825Z" fill="#39B588"/>
                </svg>
                <span className="block text-accent-green-500 text-xs xl:text-sm ml-1.25 font-semibold">
                  Recommended
                </span>
              </div>
            )}
          </div>
          <span className=" text-grey-subtext">{PLAN_DESCRIPTIONS[type]}</span>
          <h1 className="text-primary text-2xl sm:text-[28px] md:text-4lg-small lg:text-4xl text-black font-bold leading-none mt-5 sm:mt-7.5">
            {amount > 0 ? toCurrency(amount, (country as CountryInterface)?.currency, 0) : "Free"}
          </h1>
          <span className="text-black-placeholder text-sm sm:text-1sm lg:text-base mb-2 block">{interval_text}</span>

          <AppBtn
            isBlock
            color={(amount > 0 ? "primary" : "primary-outline") as any}
            className="mb-5 mt-4"
            href="/app/sign-up"
          >
            {amount === 0 ? "Try for free" : "Start 10 day trial"}
          </AppBtn>
          <hr className="bg-grey-outline lg:block hidden" />
        </div>

        <div className="lg:block hidden lg:px-7 px-4">
          <div className="pb-8">
            <h6 className=" font-semibold text-base md:text-lg text-dark mb-2 ">Includes:</h6>
            <ul className="flex flex-col space-y-2.5 mb-4">
              {descriptions.map((feature, index) =>
                feature.features.map((f, i) => (
                  <li className="flex items-center" key={`${index}-${i}`}>
                    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" className="text-accent-green-500">
                      <path
                        d="M10 1L3.8125 7L1 4.27273"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="inline-block ml-2.5 text-black text-sm sm:text-1sm pt-0.5">{f}</span>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>

        <div className="lg:hidden block bg-grey-bgdark lg:px-7 px-4 border-t border-b-grey-outline">
          <button
            className=" text-primary-700 py-4 font-semibold flex items-center m-auto"
            onClick={() => setShowDescription(!showDescription)}
          >
            <span className="mt-0.75">{showDescription ? "View Less" : "View Features"}</span>
            {/* prettier-ignore */}
            <svg viewBox="0 0 20 20" fill="none" className={`${showDescription?"rotate-180" :"rotate-0"} transition-transform ml-0.75 sm:ml-1 text-primary-700 transform flex-shrink-0 w-4 sm:w-5`}>
            <path d="M15 7.5L10 12.5L5 7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          </button>
          <div className={`${showDescription ? "h-[initial] pb-2 lg:pb-4" : "h-0"} overflow-hidden duration-300`}>
            <ul className="flex flex-col space-y-2.5 mb-4">
              {descriptions.map((feature, index) =>
                feature.features.map((f, i) => (
                  <li className="flex items-center" key={`${index}-${i}`}>
                    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" className="text-accent-green-500">
                      <path
                        d="M10 1L3.8125 7L1 4.27273"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="inline-block ml-2.5 text-black text-sm sm:text-1sm pt-0.5">{f}</span>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      </article>
    </>
  );
};

const PLAN_DESCRIPTIONS = {
  STARTER: "For businesses just starting out with few products",
  BASIC: "For growing businesses looking to handle their sales better",
  BUSINESS_PLUS: "For bigger businesses looking to sell more efficiently",
};

export default PlanCard;
