import React, { useEffect } from "react";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import Badge from "../../ui/badge";
import { AppBtn } from "../../ui/buttons";
import gsap, { Power3 } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import LazyImage from "../../lazy-image";
import AnimatedHeaderText from "../commons/animated-header-text";
import { getSizedImage } from "../../../assets/js/utils/utils";

const HomePageFeatures = () => {
  const { width, screen } = useScreenSize();
  const isSmall = width < 1024;

  return (
    <section className="body-padding">
      <div className="container">
        <AnimatedHeaderText
          className="mb-10 sm:mb-12.5 lg:mb-15"
          series={[
            ["Everything", "you", "need"],
            ["to", "sell", "better"],
          ]}
        />
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-y-9 sm:gap-y-12.5 sm:gap-x-9 md:gap-x-9 lg:gap-x-12.5 xl:gap-15 feature-wrapper">
          {features.map((feature, index) => (
            <div
              className={`w-full flex flex-col items-center home-feature-group ${
                index % 2 === 0 ? "" : "lg:flex-col-reverse"
              }`}
              key={index}
            >
              <div
                className={`w-full rounded-[18px] sm:rounded-[22px] md:rounded-3xl xl:rounded-[30px] px-6 py-8 sm:py-10 sm:px-11.25 md:py-12.5 md:px-15 lg:p-10 xl:p-12.5 xl:px-12.5 ${feature.background}`}
                style={{ "--delay": "0.5s" } as React.CSSProperties}
              >
                <figure className="h-25 w-25 sm:h-30 sm:w-30 lg:h-36 lg:w-36 xl:h-40 xl:w-40">
                  <LazyImage
                    showLoader={false}
                    src={getSizedImage(feature.icon, { [BreakPoint.xs]: "w_120", default: "w_180" }, screen)}
                    alt={feature.iconAlt}
                    className="w-full"
                  />
                </figure>
                <b className="heading-five font-bold mt-4 sm:mt-5 md:mt-7 lg:mt-7.5 text-black !leading-[1.12] max-w-[200px] sm:max-w-[1000px] lg:max-w-[340px] xl:max-w-[360px] font-display inline-block">
                  {feature.title}
                </b>
                <p className="text-black sub-text mt-3 sm:mt-3.75 md:mt-4 xl:mt-5 max-w-[370px] sm:max-w-[480px] md:max-w-[520px]">
                  {feature.description}
                </p>

                {/* {feature.comingSoon && (
                  <Badge
                    text="Coming soon"
                    greyBg={false}
                    color="orange"
                    size="lg"
                    className="mt-7.5 md:mt-8 lg:mt-11"
                  />
                )} */}

                {/* {!feature?.comingSoon && ( */}
                <AppBtn
                  color="white"
                  size="lg"
                  className="mt-7.5 md:mt-8 lg:mt-11 inline-flex !font-semibold"
                  href={feature.path}
                  aria-label={feature.title}
                >
                  {feature.label}
                  {/* prettier-ignore */}
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" className="ml-2.5 transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
                      <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="currentColor" />
                    </svg>
                </AppBtn>
                {/* )} */}
              </div>
              <div className={`w-[70%] border-x-2 border-dashed h-5 sm:h-8 md:h-9 lg:h-10 ${feature.dots}`}></div>
              <div className="prevent-card-flip w-full flex justify-center">
                <figure
                  className={`w-[90%] rounded-2xl sm:rounded-[20px] md:rounded-3xl lg:rounded-[28px] xl:rounded-[30px] overflow-hidden relative feature-image safari-overflow-fix card-flip ${
                    isSmall || index % 2 === 0 ? "" : "reverse"
                  }`}
                >
                  <LazyImage
                    src={getSizedImage(feature.image, { [BreakPoint.xs]: "w_650", default: "w_750" }, screen)}
                    alt={feature.title}
                    className="w-full absolute top-0 h-full object-cover feature-x-image"
                    loaderClasses="w-full absolute top-0 h-full object-cover"
                  />
                  <div
                    className={`absolute top-0 left-0 h-full w-full bg-opacity-20 flex flex-col items-center bg-primary-900 opacity-0 pointer-events-none feature-mockup`}
                  >
                    <figure className="w-[60%] sm:w-[55%] relative top-[12.5%] sm:top-[15%] transform translate-y-[25%] ease-out transition-all duration-300">
                      <LazyImage
                        src={getSizedImage(feature?.mockup, { [BreakPoint.xs]: "w_450", default: "w_528" }, screen)}
                        alt="Screen mockup"
                        className="w-full"
                        loaderClasses="rounded-20"
                      />
                    </figure>
                  </div>
                </figure>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const features = [
  {
    title: "Take orders via chat with one link",
    description: "Create a simple list of all your products and get a link that routes customer’s orders to whatsapp.",
    background: "bg-accent-yellow-pastel",
    icon: "https://res.cloudinary.com/catlog/image/upload/v1670357114/illustrations/store-link.webp",
    image: "https://res.cloudinary.com/catlog/image/upload/q_80/v1670352425/front-pages/woman-with-phone.webp",
    dots: "border-accent-yellow-500",
    iconAlt: "Catlog Store Link Illustration",
    label: "About Store Links",
    path: "/store-links",
    mockup:
      "https://res.cloudinary.com/catlog/image/upload/v1670369160/front-pages/mockups/customer-whatsapp-order.webp",
  },
  {
    title: "Keep track of your Orders & Customers",
    description: "Easily record orders you’ve received on whatsapp so nothing gets missed when it’s time to deliver.",
    background: "bg-accent-red-pastel",
    icon: "https://res.cloudinary.com/catlog/image/upload/v1670357113/illustrations/orders.webp",
    image: "https://res.cloudinary.com/catlog/image/upload/q_80/v1670352425/front-pages/man-with-tab.webp",
    dots: "border-accent-red-500",
    iconAlt: "Catlog Orders Illustration",
    path: "/orders-and-customers",
    label: "About Orders & Customers",
    mockup:
      "https://res.cloudinary.com/catlog/image/upload/v1670369160/front-pages/mockups/orders-dashboard-mobile.webp",
  },
  {
    title: "Seamlessly collect payments",
    description: "Get a free bank account, collect payment in multiple ways & create professional invoices",
    background: "bg-accent-green-pastel",
    icon: "https://res.cloudinary.com/catlog/image/upload/v1670357113/illustrations/payments.webp",
    image: "https://res.cloudinary.com/catlog/image/upload/q_80/v1670352425/front-pages/woman-with-card.webp",
    dots: "border-accent-green-500",
    iconAlt: "Catlog Payments Illustration",
    path: "/payments",
    label: "About Payments",
    mockup:
      "https://res.cloudinary.com/catlog/image/upload/v1670369160/front-pages/mockups/customer-pay-invoice-shortened.webp",
  },
  {
    title: "Effortlessly get items delivered",
    description: "Get multiple channels to deliver your items at discounted prices.",
    background: "bg-accent-orange-pastel",
    icon: "https://res.cloudinary.com/catlog/image/upload/v1670357112/illustrations/delivery.webp",
    image: "https://res.cloudinary.com/catlog/image/upload/q_80/v1670352425/front-pages/delivery-man.webp",
    dots: "border-accent-orange-500",
    iconAlt: "Catlog Deliveries Illustration",
    label: "About Deliveries",
    path: "/deliveries",
    // comingSoon: true,
    mockup: "https://res.cloudinary.com/catlog/image/upload/v1670369160/front-pages/mockups/deliveries-dashboard.webp",
  },
];

export default HomePageFeatures;
